var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-supplier-info" },
    },
    [
      _c("h3", [_vm._v("Информация о поставщике (исполнителе)")]),
      !this.$props.purchaseitem.is_individual_supplier
        ? _c(
            "ul",
            {
              staticClass:
                "atmo-content-block-2-columns atmo-no-item-separator",
            },
            [
              _c("li", [
                _c("dt", [_vm._v("Наименование:")]),
                _c("dd", [
                  _c("a", {
                    attrs: {
                      href: `/organizations/${this.$props.supplier.id}`,
                    },
                    domProps: {
                      textContent: _vm._s(this.$props.supplier.title),
                    },
                  }),
                ]),
              ]),
              _c("li", [
                _c("dt", [_vm._v("ИНН:")]),
                _c("dd", {
                  domProps: { textContent: _vm._s(this.$props.supplier.inn) },
                }),
              ]),
              this.$props.supplier.kpp
                ? _c("li", [
                    _c("dt", [_vm._v("КПП:")]),
                    _c("dd", {
                      domProps: {
                        textContent: _vm._s(this.$props.supplier.kpp),
                      },
                    }),
                  ])
                : _vm._e(),
              _c("li", [
                _c("dt", [_vm._v("Адрес:")]),
                _c("dd", {
                  domProps: {
                    textContent: _vm._s(this.$props.supplier.address),
                  },
                }),
              ]),
              _vm.$props.purchaseitem.supplierOrganizationCardAttachment !==
              null
                ? _c("li", [
                    _c("dt", [_vm._v("Карточка предприятия:")]),
                    _c("dd", [
                      _c("a", {
                        staticClass: "atmo-button-link",
                        attrs: {
                          href: _vm.$getAttachmentDownloadLink(
                            _vm.$props.purchaseitem
                              .supplierOrganizationCardAttachment.id
                          ),
                        },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$props.purchaseitem
                              .supplierOrganizationCardAttachment.name
                          ),
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        : _c(
            "ul",
            {
              staticClass:
                "atmo-content-block-2-columns atmo-no-item-separator",
            },
            [_vm._m(0)]
          ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("dt", [_vm._v("Наименование:")]),
      _c("dd", [_vm._v("Физическое лицо")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-content-group" },
    [
      _c("h5", [_vm._t("default")], 2),
      _c("div", { staticClass: "table-responsive" }, [
        _c(
          "table",
          { staticClass: "atmo-content-table atmo-attachments-main" },
          [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("№")]),
                _c("th", [_vm._v("Документ")]),
                !_vm.pivc.isPurchaseOutATMO
                  ? _c("th", [_vm._v("Подписи")])
                  : _vm._e(),
                _c("th", { staticClass: "atmo-ct-date" }, [
                  _vm._v("Дата и время направления документов"),
                ]),
                !_vm.pivc.isPurchaseOutATMO
                  ? _c("th", [_vm._v("В архиве")])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "tbody",
              [
                _vm._l(this.$props.attachments, function (attachment, index) {
                  return _c("tr", { key: index }, [
                    _c("td", { domProps: { textContent: _vm._s(index + 1) } }),
                    _c("td", [
                      _c("button", {
                        staticClass: "atmo-button-link",
                        domProps: { textContent: _vm._s(attachment.name) },
                        on: {
                          click: function ($event) {
                            return _vm.$props.pivc.downloadFileAttachment(
                              attachment
                            )
                          },
                        },
                      }),
                    ]),
                    !_vm.pivc.isPurchaseOutATMO
                      ? _c("td", [
                          _vm.$props.pivc.isContractSignedByBoth &&
                          !attachment.is_archived
                            ? _c(
                                "button",
                                {
                                  staticClass: "atmo-button-icon",
                                  attrs: {
                                    title: "Нажмите, чтобы просмотреть подписи",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$props.pivc.invokeSignaturePopup(
                                        [
                                          _vm.$props.signatures.supplier,
                                          _vm.$props.signatures.customer,
                                        ]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "atmo-icon-certificate",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          (!_vm.$props.pivc.isContractSignedBySupplier ||
                            !_vm.$props.pivc.isContractSignedByCustomer) &&
                          !attachment.is_archived &&
                          !_vm.$props.pivc.isContractDisputed
                            ? _c("div", [
                                _vm._v(" Ожидает подписи "),
                                !_vm.$props.pivc.isContractSignedBySupplier
                                  ? _c("span", [_vm._v("поставщика, ")])
                                  : _vm._e(),
                                !_vm.$props.pivc.isContractSignedByCustomer
                                  ? _c("span", [_vm._v("заказчика")])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          !attachment.is_archived &&
                          _vm.$props.pivc.isContractDisputed
                            ? _c("div", [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$props.pivc.purchaseitem.contract
                                        .acceptance_status_title
                                    ),
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c("td", { staticClass: "atmo-ct-date" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatdate")(
                            attachment.created_at,
                            "withSeconds"
                          )
                        )
                      ),
                    ]),
                    !_vm.pivc.isPurchaseOutATMO
                      ? _c("td", [
                          attachment.is_archived
                            ? _c("span", [_vm._v("Да")])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                }),
                _vm.$props.attachments < 1
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "atmo-centered",
                          attrs: { colspan: "5" },
                        },
                        [_vm._v("Нет документов")]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm.pivc.hasContract &&
      _vm.pivc.isUserEngagedCustomer &&
      !_vm.pivc.isContractSignedByCustomer &&
      _vm.pivc.isContractSignedBySupplier
        ? [
            _c(
              "ul",
              {
                staticClass:
                  "atmo-content-block-2-columns atmo-no-item-separator",
              },
              [
                _c("li", [
                  _vm._m(0),
                  _c("dd", { staticClass: "atmo-form__field atmo-inverse" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.pivc.$data.customer_contract_number,
                          expression: "pivc.$data.customer_contract_number",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.pivc.$data.customer_contract_number,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.pivc.$data,
                            "customer_contract_number",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm._t("controls"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", { staticClass: "atmo-help" }, [
      _vm._v(" Номер договора в системе учета заказчика: "),
      _c("i", { staticClass: "atmo-icon-question-mark" }),
      _c("span", { staticClass: "atmo-help__text" }, [
        _vm._v(
          "Укажите номер договора в случае если он отличается от номера договора в ИС"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
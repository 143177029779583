<template>
    <div class="d-block text-center">
        <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
            <LoginError v-if="isLoginError" />
            <b-form-group v-else-if="certificateErrorCode === 0" label="Выберите сертификат" label-class="fs-18 grey-color" class="mt-2">
                <multiselect
                    v-model="selectedCertificate"
                    :allow-clear="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="certificates"
                    :preserve-search="false"
                    :show-labels="false"
                    label="subjectName"
                    placeholder="Выберите сертификат"
                    track-by="thumbprint"
                    @input="onCertificateChange">
                    <span slot="noOptions">Нет данных</span>
                    <span slot="noResult">По вашему запросу сертификаты не найдены</span>
                    <template slot="singleLabel" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.subjectName }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.validFrom) }} по {{ getDateFormat(props.option.validTo) }}
                    </template>
                    <template slot="option" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.subjectName }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.validFrom) }} по {{ getDateFormat(props.option.validTo) }}
                    </template>
                </multiselect>
                <b-form-invalid-feedback :state="state" class="mt-3">{{ errorMessage }}</b-form-invalid-feedback>
            </b-form-group>
            <CryptoproError v-else-if="certificateErrorCode === 90097" />
            <b-container v-else class="text-danger fs-18 fw-600 text-center my-5">
                <p>{{ certificateErrorMessage }}</p>
            </b-container>
            <footer v-if="!isLoginError" class="modal-footer pb-0 px-0">
                <div class="w-100 text-left">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="onCancel">Отмена</b-button>
                    <b-button :disabled="!state" class="text-uppercase" variant="custom-green" @click="login">Продолжить</b-button>
                </div>
            </footer>
        </b-overlay>
    </div>
</template>

<script>
import LoginError from '@/components/auth/login-error.vue';
import CryptoproError from '@/components/auth/cryptopro-error.vue';
import { getUserCertificates } from 'crypto-pro';
import { helpers, required } from 'vuelidate/lib/validators';
import { $api } from '@/services/backend/api';

const certBackError = (param) => helpers.withParams({ type: 'certBackError', value: param }, (value) => !helpers.req(value) || param === false);

export default {
    name: 'login-with-certificate',
    components: { CryptoproError, LoginError },
    data() {
        return {
            certificates: [],
            isLoading: false, // modal выбора сертификатов
            isLoginError: false,
            selectedCertificate: null,
            certificateErrorMessage: null,
            certificateErrorCode: 0,
            certErrorText: '',
            certBackError: false
        };
    },
    validations() {
        return {
            selectedCertificate: {
                required,
                certBackError: certBackError(this.certBackError)
            }
        };
    },
    async mounted() {
        this.$emit('loadStart');
        try {
            this.certificates = await getUserCertificates();
        } catch (e) {
            this.certificateErrorMessage = e.message;
        }
        this.$emit('loadEnd');
        this.$emit('hideClose');
    },
    methods: {
        onCancel() {
            this.isLoginError = true;
            this.$emit('showClose');
        },
        onCertificateChange(value) {
            if (value) {
                this.certBackError = false;
            }
        },
        async login() {
            this.$emit('loadStart');
            this.isLoading = true;

            try {
                await $api.authentication.loginByCertificate(this.selectedCertificate).then((response) => {
                    // устанавливаем выбранный сертификат в хранилище
                    this.$store.commit('set_current_thumbprint', this.selectedCertificate.thumbprint);
                    this.$store
                        .dispatch('login', {
                            token: response.access_token,
                            user: response.user,
                            organization: response.organization
                        })
                        .then(() => {
                            window.location.assign('/cabinet');
                        });
                    this.$emit('close');
                });
            } catch (e) {
                this.certErrorText = e.response.data.message;
                this.certBackError = true;
            }
            this.$emit('loadEnd');
            this.isLoading = false;
        }
    },
    computed: {
        state() {
            return this.$v !== null ? !this.$v.selectedCertificate.$invalid : null;
        },
        errorMessage() {
            if (this.$v) {
                if (this.certificateErrorMessage) return this.certificateErrorMessage;
                if (!this.$v.selectedCertificate.required) return 'Сертификат не выбран';
                if (!this.$v.selectedCertificate.certBackError) return this.certErrorText;
            }
            return '';
        }
    }
};
</script>

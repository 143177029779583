<template>
    <div class="atmo-component-wrapper">
        <h5>Документы</h5>

        <div class="table-responsive">
            <table class="atmo-content-table atmo-annex-attachments">
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Документ</th>
                        <th>Дата и время направления документа</th>

                        <!-- // DEPRECATED: acconrding to the QA document comment. -->
                        <!-- <th>Подписи</th> -->

                        <th>В архиве</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(attachment, index) in this.$props.annex.attachments" v-bind:key="index">
                        <td v-text="index + 1"></td>
                        <td>
                            <button v-on:click="$props.pivc.downloadFileAttachment(attachment)" class="atmo-button-link" v-text="attachment.name"></button>
                        </td>
                        <td>{{ attachment.created_at | formatdate('withSeconds') }}</td>

                        <!-- // DEPRECATED: acconrding to the QA document comment. -->
                        <!-- <td>
                        <button v-if="!attachment.is_archived && $props.annex.isSignedByBoth()"
                            v-on:click.stop="$props.pivc.invokeSignaturePopup([$props.annex.signatures.supplier, $props.annex.signatures.customer])"
                            class="atmo-button-icon"
                            title="Нажмите, чтобы просмотреть подписи">
                            <i class="atmo-icon-certificate"></i>
                        </button>
                        <p v-if="!attachment.is_archived && !annex.isSignedByBoth()">Ожидает подписи
                            <span v-if="!annex.is_signed_by.supplier">поставщика, </span>
                            <span v-if="!annex.is_signed_by.customer">заказчика</span>
                        </p>
                    </td> -->

                        <td><span v-if="attachment.is_archived">Да</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { require: true, type: Object },
        annex: { required: true, type: Object }
    }
};
</script>

import 'babel-polyfill';
import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import store from './store';
import axios from 'axios';

window.axios = axios;
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import App from './App';
import router from './router';
import Default from '@/layout/Layout1';
// import AuthLayout from '@/layout/AuthLayout';
import Multiselect from 'vue-multiselect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt, faTrash, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vuelidate from 'vuelidate';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Dialog from 'bootstrap-vue-dialog';
import FileUploader from '@/components/common/FileUploader';
import DateTimePicker from 'vue-ctk-date-time-picker';
import VueCurrencyInput from 'vue-currency-input';
import StopNumberMousewheelDirective from './directives/stop-number-mousewheel';

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import momentMixin from '@/mixins/momentMixin';
import formatMixins from '@/mixins/formatMixins';
import toastMixin from '@/mixins/toast';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { InputInt } from 'vue-only-number';
import Fragment from 'vue-fragment';

import PurchaseSpecificationModal from '@/components/common/purchase-specification-modal.vue';
import FormRowSpecificationTable from '@/components/common/form-rows/form-row-specification-table';
import FormRowSingleSelect from '@/components/common/form-rows/form-row-select-single';
import FormRowSingleSelectWithSearch from '@/components/common/form-rows/form-row-single-select-with-search.vue';
import FormRowEditDate from '@/components/common/form-rows/form-row-edit-date';
import FormRowEditPrice from '@/components/common/form-rows/form-row-edit-price';
import FormRowEditText from '@/components/common/form-rows/form-row-edit-text';
import FormRowEditTextarea from '@/components/common/form-rows/form-row-edit-textarea';
import FormRowSingleLineDocumentUpload from '@/components/common/form-rows/form-row-single-line-document-upload';
import FormRowEditDadataAddress from '@/components/common/form-rows/form-row-edit-dadata-address';
import FormRowEditPhone from '@/components/common/form-rows/form-row-edit-phone';
import FormRowEditEmail from '@/components/common/form-rows/form-row-edit-email';
import FormRowEditCheckbox from '@/components/common/form-rows/form-row-edit-checkbox';
import FormRowSingleDocumentUploadNew from '@/components/common/form-rows/form-row-single-document-upload-new';

import TextHeader from '@/components/common/form-rows/text-header';
import TextRowLink from '@/components/common/form-rows/text-row-link';
import TextRow from '@/components/common/form-rows/text-row';
import TextRowPrice from '@/components/common/form-rows/text-row-price';
import TextRowDatetime from '@/components/common/form-rows/text-row-datetime';
import TextRowDate from '@/components/common/form-rows/text-row-date';
import TextRowDocuments from '@/components/common/form-rows/text-row-documents';
import TextRowAddresses from '@/components/common/form-rows/text-row-addresses';
import TextRowPhone from '@/components/common/form-rows/text-row-phone';

import FilterInputText from '@/components/common/filter-components/filter-input-text';
import FilterInputCurrency from '@/components/common/filter-components/filter-input-currency';
import FilterSingleSelect from '@/components/common/filter-components/filter-single-select';

import { $api } from '@/services/backend/api';

// Rearchitected.

import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import FormatNumber from '@lib/js/src/misc/FormatNumber';
import FormatDate from '@lib/js/src/misc/FormatDate';
import Config from '@core/js/ddriven/application/config/Config.ts';
import globalDictionariesMixin from '@/mixins/globalDictionaries';

require('@core/styles/app/styles.less');
require('themes/styles/app/styles.less');
// import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import ActionButtonSmall from '@/components/common/action-button-small.vue';
import ButtonCloseModal from '@/components/common/button-close-modal.vue';
import TextHeaderNew from '@/components/common/form-rows/text-header-new.vue';
import FormRowEditAddress from '@/components/common/form-rows/form-row-edit-address.vue';
import FormRowEditAddressWoCoordinates from '@/components/common/form-rows/form-row-edit-address-wo-coordinates.vue';
import FormRowEditAddressesNew from '@/components/common/form-rows/form-row-edit-addresses-new.vue';
import TextRowSingleDocument from '@/components/common/form-rows/text-row-single-document.vue';
Vue.component('v-select', vSelect);

Vue.filter('formatnumber', FormatNumber.createFormatter(FormatNumber.configs.default));
Vue.filter('formatnumber:nodecimal', FormatNumber.createFormatter(FormatNumber.configs.nodecimal));
Vue.filter('formatnumber:rounded', FormatNumber.createFormatter(FormatNumber.configs.nopad));
Vue.filter('formatdate', FormatDate.format);

// Rearchitectured things end.

const PurchasesPreloader = () => import(/* webpackChunkName: "main" */ '@/components/elements/PurchasesPreloader');
const NewsPreloader = () => import(/* webpackChunkName: "main" */ '@/components/elements/NewsPreloader');
const InformationPreloader = () => import(/* webpackChunkName: "main" */ '@/components/elements/InformationPreloader');
const SystemPreloader = () => import(/* webpackChunkName: "main" */ '@/components/elements/SystemPreloader');
const EmptyRequest = () => import(/* webpackChunkName: "main" */ '@/components/elements/EmptyRequest');
const ShowCertificateModal = () => import(/* webpackChunkName: "main" */ '@/components/elements/ShowCertificateModal');
const ShowCertificateContractModal = () => import(/* webpackChunkName: "main" */ '@/components/elements/ShowCertificateContractModal');
const ShowCertificateDisagreementContractModal = () => import(/* webpackChunkName: "main" */ '@/components/elements/ShowCertificateDisagreementContractModal');

library.add(faPencilAlt, faTrash, faChevronUp, faChevronDown);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('multiselect', Multiselect);
Vue.component('default-layout', Default);
// Vue.component('auth-layout', AuthLayout);
Vue.component('purchases-preloader', PurchasesPreloader);
Vue.component('news-preloader', NewsPreloader);
Vue.component('information-preloader', InformationPreloader);
Vue.component('system-preloader', SystemPreloader);
Vue.component('empty-request', EmptyRequest);
Vue.component('certificate-modal', ShowCertificateModal);
Vue.component('certificate-contract-modal', ShowCertificateContractModal);
Vue.component('certificate-disagreement-contract-modal', ShowCertificateDisagreementContractModal);
Vue.component('file-uploader', FileUploader);
Vue.component('date-time-picker', DateTimePicker);

Vue.directive('stop-number-mousewheel', StopNumberMousewheelDirective);
Vue.directive('v-input-int', InputInt);

Vue.component('purchase-specification-modal', PurchaseSpecificationModal);
Vue.component('form-row-specification-table', FormRowSpecificationTable);
Vue.component('form-row-single-select', FormRowSingleSelect);
Vue.component('form-row-single-select-with-search', FormRowSingleSelectWithSearch);
Vue.component('form-row-edit-date', FormRowEditDate);
Vue.component('form-row-edit-price', FormRowEditPrice);
Vue.component('form-row-edit-text', FormRowEditText);
Vue.component('form-row-edit-textarea', FormRowEditTextarea);
Vue.component('form-row-edit-dadata-address', FormRowEditDadataAddress);
Vue.component('form-row-edit-address', FormRowEditAddress);
Vue.component('form-row-edit-address-wo-coordinates', FormRowEditAddressWoCoordinates);
Vue.component('form-row-edit-addresses-new', FormRowEditAddressesNew);
Vue.component('form-row-edit-phone', FormRowEditPhone);
Vue.component('form-row-edit-email', FormRowEditEmail);
Vue.component('form-row-edit-checkbox', FormRowEditCheckbox);
Vue.component('form-row-single-line-document-upload', FormRowSingleLineDocumentUpload);
Vue.component('form-row-single-document-upload-new', FormRowSingleDocumentUploadNew);

Vue.component('text-header', TextHeader);
Vue.component('text-header-new', TextHeaderNew);
Vue.component('text-row', TextRow);
Vue.component('text-row-single-document', TextRowSingleDocument);
Vue.component('text-row-addresses', TextRowAddresses);
Vue.component('text-row-date', TextRowDate);
Vue.component('text-row-datetime', TextRowDatetime);
Vue.component('text-row-documents', TextRowDocuments);
Vue.component('text-row-link', TextRowLink);
Vue.component('text-row-phone', TextRowPhone);
Vue.component('text-row-price', TextRowPrice);

Vue.component('filter-input-text', FilterInputText);
Vue.component('filter-input-currency', FilterInputCurrency);
Vue.component('filter-single-select', FilterSingleSelect);

Vue.component('action-button-small', ActionButtonSmall);
Vue.component('button-close-modal', ButtonCloseModal);

Vue.mixin(momentMixin);
Vue.mixin(formatMixins);
Vue.mixin(toastMixin);

const debug = process.env.NODE_ENV !== 'production';

axios.defaults.baseURL = Config.get('api.config.legacy.url');

Vue.config.productionTip = false;

Vue.use(VueCurrencyInput);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(datePicker);
Vue.use(Dialog);
Vue.use(Vuelidate);
Vue.use(Fragment.Plugin);

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_KEY || 'GTM-TESTTES',
    debug: debug,
    loadScript: true,
    vueRouter: router
});

Vue.prototype.$isPhoneConfirmationNeeded = !Config.get('theme.variables.domain.no_phone_confirmation');
Vue.prototype.$deliveryKtruItemId = parseInt(process.env.VUE_APP_DELIVERY_KTRU_ITEM_ID);

Vue.mixin({
    computed: {
        $api: () => $api
    }
});
Vue.mixin(globalDictionariesMixin);

const vueRootInstance = new Vue({
    router,
    store,
    mounted() {
        // document.body.setAttribute('svta-features', JSON.stringify(Config.get('theme.features')));
        process.env.TEST_ENV === 'e2e' && document.body.setAttribute('svta-features', JSON.stringify(Config.get('theme.features')));
    },
    render: (h) => h(App)
});

new ApplicationServiceLocator(vueRootInstance);

vueRootInstance.$mount('#app');

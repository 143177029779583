var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "footer", attrs: { fluid: "" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column align-items-start" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: {
                          to: {
                            name: "notices.fl44",
                            query: { status: "accepting" },
                          },
                        },
                      },
                      [_vm._v(" ИЗВЕЩЕНИЯ 44-ФЗ")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: {
                          to: {
                            name: "contracts.grouped",
                            params: { group: "fl44" },
                          },
                        },
                      },
                      [_vm._v(" КОНТРАКТЫ 44-ФЗ")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: {
                          to: {
                            name: "notices.fl223",
                            query: { status: "accepting" },
                          },
                        },
                      },
                      [_vm._v(" ИЗВЕЩЕНИЯ 223-ФЗ")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: {
                          to: {
                            name: "notices.commercial",
                            query: { status: "accepting" },
                          },
                        },
                      },
                      [_vm._v(" КОММЕРЧЕСКИЕ ИЗВЕЩЕНИЯ")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: { to: "/quotation-requests" },
                      },
                      [_vm._v(" ЗАПРОСЫ КП ")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: { to: "/organizations" },
                      },
                      [_vm._v(" ЗАКАЗЧИКИ И ПОСТАВЩИКИ ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column align-items-start" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "atmo-override-link-off fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: {
                          href: _vm.$links.info_materials,
                          target: "_blank",
                        },
                      },
                      [_vm._v(" ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ ")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass:
                          "atmo-override-link-off fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: {
                          href: _vm.$links.legal_documents,
                          target: "_blank",
                        },
                      },
                      [_vm._v(" Правовые документы ")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: { to: "/articles" },
                      },
                      [_vm._v(" НОВОСТИ ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "12", sm: "auto" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "flex-row-568" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex flex-column align-items-start mt-568",
                          attrs: { cols: "6", md: "12" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-10 white-color text-uppercase text-decoration-none my-1",
                            },
                            [_vm._v("Режим работы")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs-10 white-color text-decoration-none my-1",
                            },
                            [
                              _vm._v(
                                " 9:00 до 18:00 (по времени г. " +
                                  _vm._s(_vm.theme.variables.view.city) +
                                  ") "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "fs-10 white-color text-decoration-none my-2 py-2",
                  staticStyle: { cursor: "help" },
                  attrs: { md: "12", title: `v${_vm.theme.version.full}` },
                },
                [
                  _vm._v(
                    " v" +
                      _vm._s(_vm.theme.version.core) +
                      " © " +
                      _vm._s(_vm.theme.variables.view.copyright_name) +
                      " 2024 "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
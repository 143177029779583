var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      staticClass: "w-100",
      attrs: {
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        "label-cols-sm": _vm.labelColsSm,
        "label-cols-lg": _vm.labelColsLg,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mx-0" },
        [
          _c(
            "b-col",
            { staticClass: "px-0" },
            [
              _c(
                "multiselect",
                {
                  class: {
                    "is-valid": _vm.state === true,
                    "is-invalid": _vm.state === false,
                  },
                  attrs: {
                    options: _vm.options,
                    multiple: false,
                    "allow-empty": _vm.allowEmpty,
                    "close-on-select": true,
                    "clear-on-select": true,
                    "preserve-search": false,
                    "show-labels": false,
                    placeholder: _vm.placeholder,
                    label: _vm.labelField,
                    "track-by": _vm.trackBy,
                    disabled: _vm.disabled,
                  },
                  model: {
                    value: _vm.text,
                    callback: function ($$v) {
                      _vm.text = $$v
                    },
                    expression: "text",
                  },
                },
                [
                  _vm.allowEmpty
                    ? _c("template", { slot: "clear" }, [
                        _vm.value
                          ? _c("div", {
                              staticClass: "multiselect__clear",
                              on: {
                                mousedown: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.clearAll()
                                },
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c(
                    "span",
                    { attrs: { slot: "noOptions" }, slot: "noOptions" },
                    [_vm._v("Нет данных.")]
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [
                      _vm._v(
                        "Записей, соответствующих вашему запросу, не найдено."
                      ),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "px-0", attrs: { md: "auto" } },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
      !_vm.state
        ? [
            _c("b-form-invalid-feedback", { attrs: { "force-show": "" } }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-component-wrapper" }, [
    _c("h5", [_vm._v("Документы")]),
    _c("div", { staticClass: "table-responsive" }, [
      _c(
        "table",
        { staticClass: "atmo-content-table atmo-annex-attachments" },
        [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(this.$props.annex.attachments, function (attachment, index) {
              return _c("tr", { key: index }, [
                _c("td", { domProps: { textContent: _vm._s(index + 1) } }),
                _c("td", [
                  _c("button", {
                    staticClass: "atmo-button-link",
                    domProps: { textContent: _vm._s(attachment.name) },
                    on: {
                      click: function ($event) {
                        return _vm.$props.pivc.downloadFileAttachment(
                          attachment
                        )
                      },
                    },
                  }),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(attachment.created_at, "withSeconds")
                    )
                  ),
                ]),
                _c("td", [
                  attachment.is_archived
                    ? _c("span", [_vm._v("Да")])
                    : _vm._e(),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№")]),
        _c("th", [_vm._v("Документ")]),
        _c("th", [_vm._v("Дата и время направления документа")]),
        _c("th", [_vm._v("В архиве")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <template #label>
            {{ label }}:
            <div v-if="hint" class="atmo-help">
                &nbsp;<i class="atmo-icon-question-mark"></i><span class="atmo-help__text">{{ hint }}</span>
            </div>
        </template>
        <div :class="{ 'is-data-invalid': state === false }">
            <b-table :fields="fields" :items="model" :busy="isLoading" class="table-atmo mb-0" empty-text="Не добавлено адресов" show-empty>
                <template #head(actions)>
                    <span class="d-inline-block" tabindex="0" v-b-popover.hover.top="'Добавить адрес'">
                        <template v-if="addresses.length !== 0">
                            <b-dropdown :disabled="!!maxLength && model.length >= maxLength" right toggle-class="atmo-button-icon">
                                <template #button-content>
                                    <i class="atmo-icon-add-to-list"></i>
                                </template>
                                <b-dropdown-item @click="showAddressBookSelect">Из адресной книги</b-dropdown-item>
                                <b-dropdown-item @click="showFiasSelect">Ввести вручную</b-dropdown-item>
                            </b-dropdown>
                        </template>
                        <template v-else>
                            <b-button :disabled="!!maxLength && model.length >= maxLength" @click="showFiasSelect" class="atmo-button-icon">
                                <i class="atmo-icon-add-to-list"></i>
                            </b-button>
                        </template>
                    </span>
                </template>
                <template #cell(index)="row"> {{ row.index + 1 }}. </template>
                <template #cell(actions)="row">
                    <action-button-small icon="trash" @click="removeAddress(row.index)" />
                </template>
                <template #empty="scope">
                    <div class="text-center w-100">{{ scope.emptyText }}</div>
                </template>
            </b-table>
        </div>
        <template v-if="!state">
            <b-form-invalid-feedback :state="state">{{ errorMessage }}</b-form-invalid-feedback>
        </template>
        <template v-if="description">
            <b-form-text class="desc-text">
                {{ description }}
            </b-form-text>
        </template>
        <b-modal v-model="form.showAddress" title="Адрес" hide-footer centered>
            <b-row>
                <b-col>
                    <b-form-group :state="!$v.form.$invalid" class="w-100">
                        <multiselect
                            v-model="form.select_address"
                            placeholder="Выберите адрес"
                            deselect-label="Выбрано"
                            selected-label="Выбрано"
                            select-label="Выбрать"
                            track-by="short_address"
                            label="short_address"
                            :options="addresses"
                            :searchable="true"
                            :class="{ 'is-valid': !$v.form.$invalid, 'is-invalid': $v.form.$invalid }"
                            :allow-empty="false">
                            <span slot="noOptions">Нет данных.</span>
                            <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                        </multiselect>
                        <template v-if="$v.form.$invalid">
                            <b-form-invalid-feedback force-show>Адрес не выбран</b-form-invalid-feedback>
                        </template>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="py-2">
                <b-col class="text-right">
                    <b-button :disabled="$v.form.$invalid" @click="addBookAddress" class="text-uppercase" variant="custom-green">Добавить</b-button>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal v-model="form.showFias" title="Адрес" size="lg" hide-footer centered>
            <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
                <b-row>
                    <b-col>
                        <form-row-edit-address :value.sync="form.address" :v="$v.form.address" />
                        <form-row-edit-checkbox v-model="form.add_flag" label="Добавить в адресную книгу" />
                        <template v-if="form.add_flag">
                            <form-row-edit-text v-model="form.short_address" :v="$v.form.short_address" label="Короткое название" :max-length="50" />
                        </template>
                    </b-col>
                </b-row>
                <b-row class="py-2 mt-3">
                    <b-col>
                        <b-button :disabled="$v.form.$invalid" class="text-uppercase" @click="addFiasAddress" variant="custom-green">Добавить</b-button>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-modal>
    </b-form-group>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';

export default {
    name: 'form-row-edit-addresses-new',
    props: {
        value: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        hint: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        maxLength: {
            type: Number,
            default: 0
        },
        v: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            model: [],
            fields: [
                { key: 'index', label: '', thStyle: 'width: 1px;' },
                { key: 'address', label: 'Адрес' },
                { key: 'actions', thStyle: 'width: 1px;', tdClass: 'text-right', thClass: 'py-1' }
            ],
            form: {
                address: null,
                add_flag: false,
                short_address: '',
                showFias: false,
                showAddress: false,
                select_address: null
            },
            isLoading: true,
            addresses: []
        };
    },
    validations: {
        form: {
            address: { required: requiredIf((form) => form.showFias) },
            short_address: { required: requiredIf((form) => form.showFias && form.add_flag) },
            select_address: { required: requiredIf((form) => form.showAddress) }
        }
    },
    async mounted() {
        this.model = this.value;
        await this.fillData();
        this.isLoading = false;
    },
    watch: {
        value() {
            this.model = this.value;
        },
        model() {
            if (this.v) {
                this.v.$touch();
            }
            this.$emit('update:value', this.model);
        }
    },
    methods: {
        async fillData() {
            this.addresses = await this.$api.personal.addresses.getAddressesList();
        },
        async saveAddress() {
            this.isLoading = true;
            const formData = {
                short_address: this.form.short_address,
                full_address: this.form.address
            };
            if (await this.$api.personal.addresses.createAddress(formData)) await this.fillData();
            this.isLoading = false;
        },
        showAddressBookSelect() {
            this.form.showAddress = true;
            this.form.showFias = false;
            this.form.select_address = null;
        },
        showFiasSelect() {
            this.form.showAddress = false;
            this.form.showFias = true;
            this.form.address = null;
            this.form.add_flag = false;
            this.form.short_address = '';
        },
        addBookAddress() {
            const exists = this.model.findIndex((item) => item.address === this.form.select_address.full_address.address);
            if (exists === -1) this.model.push(this.form.select_address.full_address);
            this.form.showAddress = false;
        },
        async addFiasAddress() {
            if (this.form.add_flag) await this.saveAddress();
            const exists = this.model.findIndex((item) => item.address === this.form.address.address);
            if (exists === -1) this.model.push(this.form.address);
            this.form.showFias = false;
        },
        removeAddress(index) {
            this.model.splice(index, 1);
        }
    },
    computed: {
        id() {
            return 'form_row_edit_addresses_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Добавьте как минимум один адрес';
            }
            return '';
        }
    }
};
</script>
<style scoped>
.is-data-invalid {
    border: 1px solid red;
    border-radius: 2px;
}
</style>

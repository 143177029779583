<template>
    <div class="atmo-content-group">
        <h3 v-if="this.$props.origin === 'notice'">
            Спецификация
            <slot></slot>
        </h3>
        <h5 v-else>
            Спецификация
            <slot></slot>
        </h5>

        <div class="table-responsive">
            <table class="atmo-content-table atmo-deliverables" v-bind:class="{ 'atmo-empty': this.isempty }">
                <thead>
                    <tr>
                        <th class="atmo-center">№</th>
                        <th style="min-width: 400px">Наименование ТРУ</th>
                        <th class="atmo-ctc-quantity">Количество</th>
                        <th class="atmo-ctc-okpd-symbolic-code">Ед.изм</th>
                        <th class="atmo-ctc-price-per-unit">Цена за ед., руб.</th>
                        <th class="atmo-ctc-price-total">Стоимость, руб.</th>
                        <th v-if="this.$props.iseditable">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <fragment v-for="(deliverable, index) in this.$props.deliverables" v-bind:key="index">
                        <tr :class="visible[index] ? 'border-bottom-0' : ''">
                            <td class="atmo-center" v-text="index + 1"></td>
                            <td>
                                <action-button-small v-if="deliverable.gsc_details || deliverable.okpd_code" :title="(!visible[index] ? 'Показать' : 'Скрыть') + ' характеристики'" v-b-toggle="'collapse-' + index" :icon="!visible[index] ? 'eye' : 'eye-slash'" />
                                <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                {{ deliverable.title }}
                            </td>
                            <td v-if="$props.iseditable" class="atmo-ctc-quantity">
                                <span class="atmo-form__field atmo-inverse atmo-small">
                                    <input v-model.number="deliverable.quantity" v-stop-number-mousewheel min="1" required type="number" />
                                </span>
                            </td>
                            <td v-else class="atmo-ctc-quantity" v-text="deliverable.quantity"></td>

                            <td class="atmo-ctc-okpd-symbolic-code" v-text="deliverable.okei_symbolic_code"></td>
                            <td class="atmo-ctc-price-per-unit">{{ deliverable.price_per_unit | formatnumber }}</td>
                            <td class="atmo-ctc-price-total">{{ deliverable.price_total | formatnumber }}</td>
                            <td v-if="$props.iseditable">
                                <button class="atmo-button-icon" title="Удалить позицию из спецификации" v-on:click="$props.removehandler(index)"><i class="atmo-icon-trash"></i></button>
                            </td>
                        </tr>
                        <tr v-show="visible[index]">
                            <td colspan="7">
                                <div v-if="deliverable.gsc_details">
                                    <p v-if="deliverable.gsc_details.registration_number"><strong>Рег. номер ТРУ:</strong> {{ deliverable.gsc_details.registration_number }}</p>
                                    <ul class="fs-14">
                                        <li v-if="deliverable.okpd_code"><strong>Код ОКПД2:&nbsp;</strong>{{ deliverable.okpd_code }}</li>
                                        <li v-if="deliverable.gsc_details.group"><strong>Группа ТРУ:&nbsp;</strong>{{ deliverable.gsc_details.group }}</li>
                                        <li v-if="deliverable.gsc_details.title"><strong>Наименование:&nbsp;</strong>{{ deliverable.gsc_details.title }}</li>
                                        <li v-if="deliverable.gsc_details.type"><strong>Тип:&nbsp;</strong>{{ deliverable.gsc_details.type }}</li>
                                    </ul>
                                </div>
                                <DeliverableDetails.view v-bind:description="deliverable.gsc_details ? deliverable.gsc_details.description : null" v-bind:requirements="deliverable.requirements" />
                            </td>
                        </tr>
                    </fragment>

                    <tr v-if="this.isempty">
                        <td colspan="3">Спецификация пуста</td>
                    </tr>
                    <tr v-else class="atmo-totals">
                        <td class="atmo-right" colspan="6">Стоимость итого, руб.:&emsp;&emsp;{{ price_total | formatnumber }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <slot name="errors"></slot>

        <button v-if="this.$props.iseditable" class="atmo-button" v-on:click.stop="$props.selectorpopup">Добавить спецификацию</button>
    </div>
</template>

<script>
import { round } from 'lodash';
import DeliverableDetailsView from './DeliverableDetails.view.vue';

export default {
    computed: {
        isempty: function () {
            return this.$props.deliverables.length < 1;
        },
        // REFACTOR: This hase to be refactored away towards unsing the deliverables collection with a dedicated getter / method.
        price_total: function () {
            return round(
                this.$props.deliverables.reduce((accumulator, deliverable) => {
                    return (accumulator += deliverable.price_total);
                }, 0),
                2
            );
        }
    },
    props: {
        iseditable: { default: false, type: Boolean },
        selectorpopup: { default: null, type: Function },
        origin: { required: true, type: String },
        deliverables: { required: true, type: Array },
        removehandler: { type: Function }
    },
    components: {
        'DeliverableDetails.view': DeliverableDetailsView
    },
    data() {
        return {
            visible: []
        };
    }
};
</script>

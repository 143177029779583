var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("h2", { staticClass: "grey-color font-weight-bold my-3" }, [
                _vm._v("Спецификация"),
              ]),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  !_vm.noAddSpec
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "custom-green" },
                          on: {
                            click: function ($event) {
                              _vm.showSpec = true
                            },
                          },
                        },
                        [_vm._v("Добавить позицию спецификации")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "py-3", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "fs-14 grey-color" }, [
            _c(
              "div",
              {
                staticClass: "table-responsive",
                class: _vm.v.$invalid || _vm.specErrors ? "is-invalid" : "",
              },
              [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { width: "1" } }),
                      _c("th", [_vm._v("Наименование товара/работы/услуги")]),
                      _c(
                        "th",
                        {
                          staticClass: "text-right",
                          staticStyle: { "min-width": "140px" },
                          attrs: { width: "160px" },
                        },
                        [_vm._v("Количество")]
                      ),
                      _c("th", { attrs: { width: "100px", nowrap: "" } }, [
                        _vm._v("Ед.изм"),
                      ]),
                      _vm.withPrice
                        ? _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { "min-width": "140px" },
                              attrs: { width: "160px" },
                            },
                            [_vm._v("Цена за ед., руб.")]
                          )
                        : _vm._e(),
                      _vm.withPrice
                        ? _c(
                            "th",
                            {
                              staticClass: "text-right",
                              staticStyle: { "min-width": "140px" },
                              attrs: { width: "140px" },
                            },
                            [_vm._v("Стоимость, руб.")]
                          )
                        : _vm._e(),
                      _c("th", { attrs: { width: "1" } }),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.specs, function (item, index) {
                        return _c("fragment", { key: index }, [
                          _c("tr", [
                            _c("td", { staticClass: "align-text-top" }, [
                              _vm._v(_vm._s(item.number)),
                            ]),
                            _c(
                              "td",
                              { staticClass: "align-text-top" },
                              [
                                _c("action-button-small", {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle",
                                      value: "collapse-" + index,
                                      expression: "'collapse-' + index",
                                    },
                                  ],
                                  attrs: {
                                    title:
                                      (!_vm.visible[index]
                                        ? "Показать"
                                        : "Скрыть") + " характеристики",
                                    icon: !_vm.visible[index]
                                      ? "eye"
                                      : "eye-slash",
                                  },
                                }),
                                _c("b-collapse", {
                                  staticClass: "d-none",
                                  attrs: { id: "collapse-" + index },
                                  model: {
                                    value: _vm.visible[index],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.visible, index, $$v)
                                    },
                                    expression: "visible[index]",
                                  },
                                }),
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ],
                              1
                            ),
                            _vm.withCountEdit
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "align-text-top",
                                    staticStyle: { "text-align": "right" },
                                  },
                                  [
                                    _c("formatted-input-number", {
                                      attrs: { decimals: 9, padright: false },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (fim) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "atmo-component-wrapper",
                                                  },
                                                  [
                                                    !fim.isInputFocused
                                                      ? _c("button", {
                                                          staticClass:
                                                            "atmo-button--small atmo-input",
                                                          class: {
                                                            "atmo-invalid":
                                                              !fim.isInputValid,
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              fim.formatted
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return fim.focus.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            focus:
                                                              fim.onTriggerFocus,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              fim.isInputFocused,
                                                            expression:
                                                              "fim.isInputFocused",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "atmo-form__field atmo-inverse atmo-small",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model.number",
                                                              value:
                                                                _vm.specs[index]
                                                                  .count,
                                                              expression:
                                                                "specs[index].count",
                                                              modifiers: {
                                                                number: true,
                                                              },
                                                            },
                                                            {
                                                              name: "stop-number-mousewheel",
                                                              rawName:
                                                                "v-stop-number-mousewheel",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled:
                                                              _vm.isDeliverySpec(
                                                                item
                                                              ),
                                                            required: "",
                                                            type: "number",
                                                            step: "0.000000001",
                                                            min: "0.000000001",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.specs[index]
                                                                .count,
                                                          },
                                                          on: {
                                                            blur: [
                                                              fim.processBlurEvent,
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$forceUpdate()
                                                              },
                                                            ],
                                                            keydown:
                                                              fim.processKeydownEvent,
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.specs[
                                                                    index
                                                                  ],
                                                                  "count",
                                                                  _vm._n(
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                )
                                                              },
                                                              fim.processInputEvent,
                                                            ],
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "td",
                                  {
                                    staticClass: "align-text-top",
                                    staticStyle: { "text-align": "right" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          parseFloat(
                                            _vm.specs[index].count
                                          ).toPrecision()
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                            _c("td", { staticClass: "align-text-top" }, [
                              _vm._v(_vm._s(item.okei.local_symbol)),
                            ]),
                            _vm.withPrice
                              ? _c(
                                  "td",
                                  { staticClass: "align-text-top" },
                                  [
                                    _c("currency-input", {
                                      staticClass:
                                        "form-control fs-14 text-right",
                                      class:
                                        _vm.specs[index].price > 0
                                          ? ""
                                          : "is-invalid",
                                      attrs: { currency: "RUB", locale: "ru" },
                                      model: {
                                        value: _vm.specs[index].price,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.specs[index],
                                            "price",
                                            $$v
                                          )
                                        },
                                        expression: "specs[index].price",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.withPrice
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "align-text-top",
                                    staticStyle: { "text-align": "right" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatPrice(
                                            _vm.roundToTwoDecimals(
                                              _vm.specs[index].price *
                                                _vm.specs[index].count
                                            )
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "td",
                              {
                                staticClass: "align-text-top",
                                attrs: { nowrap: "" },
                              },
                              [
                                !_vm.noDeleteSpec || _vm.isDeliverySpec(item)
                                  ? _c("action-button-small", {
                                      attrs: { icon: "trash" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeSpec(index)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "tr",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.visible[index],
                                  expression: "visible[index]",
                                },
                              ],
                            },
                            [
                              _c(
                                "td",
                                { attrs: { colspan: _vm.withPrice ? 7 : 5 } },
                                [
                                  _c("p", [
                                    _c("b", [_vm._v("Рег.номер ТРУ:")]),
                                    _vm._v(" " + _vm._s(item.ktru.reg_number)),
                                  ]),
                                  _c("p", [
                                    _c("b", [_vm._v("Группа ТРУ:")]),
                                    _vm._v(
                                      " " + _vm._s(item.ktru.ktru_group_name)
                                    ),
                                  ]),
                                  _c("p", [
                                    _c("b", [_vm._v("Наименование:")]),
                                    _vm._v(" " + _vm._s(item.ktru.name)),
                                  ]),
                                  _c("p", [
                                    _c("b", [_vm._v("Тип:")]),
                                    _vm._v(" " + _vm._s(item.ktru.type)),
                                  ]),
                                  item.okpd
                                    ? _c("p", [
                                        _c("b", [_vm._v("Код ОКПД2:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.okpd ? item.okpd.code : "—"
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  item.ktru.description
                                    ? _c("p", [
                                        _c("b", [_vm._v("Описание:")]),
                                        _vm._v(
                                          " " + _vm._s(item.ktru.description)
                                        ),
                                      ])
                                    : _vm._e(),
                                  item.ktru.specs && item.ktru.specs.length > 0
                                    ? _c("p", [
                                        _c("b", [_vm._v("Характеристики:")]),
                                      ])
                                    : _vm._e(),
                                  item.ktru.specs && item.ktru.specs.length > 0
                                    ? _c(
                                        "ul",
                                        _vm._l(
                                          item.ktru.specs,
                                          function (spec, specIndex) {
                                            return _c(
                                              "li",
                                              {
                                                key:
                                                  index + "_spec_" + specIndex,
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(_vm._s(spec.name)),
                                                ]),
                                                _vm._v(
                                                  " - " +
                                                    _vm._s(spec.value) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }),
                      _vm.specs.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                attrs: {
                                  colspan: _vm.withPrice ? 7 : 5,
                                  align: "center",
                                },
                              },
                              [_vm._v("Не добавлено позиций спецификации")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm.specErrors
              ? _c("p", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.specErrorMessage)),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm.withPrice
        ? _c("text-row-price", {
            attrs: { label: "Общая стоимость", value: _vm.roundedSpecSum },
          })
        : _vm._e(),
      _c("purchase-specification-modal", {
        attrs: { show: _vm.showSpec },
        on: { cancel: _vm.cancelSpecModal, add: _vm.addSpec },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
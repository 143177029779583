<template>
    <div id="atmo-proposals" class="atmo-hash-jump-point atmo-content-group">
        <h3>Предложения участников закупочной сессии</h3>

        <p v-if="!$props.pivc.hasPoroposals && !$props.pivc.isProposalsInfoHidden">Нет поданных предложений</p>
        <p v-if="$props.pivc.isProposalsInfoHidden && $props.pivc.purchaseitem.hide_proposals_at !== null">Период скрытия предложений участников в закупочной сессии наступил {{ $formatDateTimeIn($props.pivc.purchaseitem.hide_proposals_at) }}</p>
        <p v-if="!$props.pivc.isProposalsInfoHidden && $props.pivc.purchaseitem.hide_proposals_at !== null">Предложения участников будут скрыты {{ $formatDateTimeIn($props.pivc.purchaseitem.hide_proposals_at) }}</p>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator" v-if="$props.pivc.purchaseitem.protocol_date">
            <li>
                <dt>Дата и время формирования итогового протокола:</dt>
                <dd>{{ $props.pivc.purchaseitem.protocol_date | formatdate('withSeconds') }}</dd>
            </li>
        </ul>

        <div v-if="!$props.pivc.isProposalsInfoHidden" class="table-responsive">
            <table v-if="$props.pivc.hasPoroposals" class="atmo-content-table atmo-proposals">
                <thead>
                    <tr>
                        <th class="atmo-place">Место</th>
                        <th class="atmo-status">Статус</th>
                        <th class="atmo-submit-date">Дата и время подачи</th>
                        <th class="atmo-price-total">Стоимость без НДС, руб.</th>
                        <th class="atmo-price-total">Стоимость с НДС, руб.</th>
                        <th class="atmo-supplier">Поставщик</th>
                        <th v-if="($props.pivc.isPurchaseSummarized || $props.pivc.hasContract) && $props.pivc.isUserEngagedCustomer" class="atmo-attachments">Документы</th>
                        <th v-if="$props.pivc.isPurchaseSummarized || $props.pivc.hasContract" class="atmo-signature">Эл. подпись</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(proposal, index) in this.$props.proposals" v-bind:key="index">
                        <td v-text="index + 1" class="atmo-place"></td>
                        <td class="atmo-status">{{ proposal.status.title }} <span v-if="proposal.is_current_supplier_proposal">(Мое)</span></td>
                        <td class="atmo-submit-date">{{ proposal.submitted_at | formatdate('withSeconds') }}</td>
                        <td class="atmo-price-total">{{ proposal.price_wo_vat ? $formatPrice(proposal.price_wo_vat) : '&mdash;' }}</td>
                        <td class="atmo-price-total">{{ proposal.total_price | formatnumber }}</td>
                        <td class="atmo-supplier">
                            <span v-if="$props.pivc.isPurchaseSummarized || $props.pivc.hasContract || $props.pivc.hasPurchaseProtocol">
                                <router-link v-bind:to="{ name: 'OrganizationShow', params: { id: proposal.supplier.id } }" target="_blank" class="atmo-button-link">{{ proposal.supplier.title_brief }}</router-link>
                            </span>
                            <span v-else>Поставщик {{ index + 1 }}</span>
                        </td>
                        <td v-if="($props.pivc.isPurchaseSummarized || $props.pivc.hasContract) && $props.pivc.isUserEngagedCustomer" class="atmo-attachments">
                            <ul v-if="proposal.hasAttachments()">
                                <li v-for="(attachment, index) in proposal.attachments" v-bind:key="index">
                                    <button v-on:click="$props.pivc.downloadFileAttachment(attachment)" class="atmo-button-link" v-text="attachment.name"></button>
                                </li>
                            </ul>
                            <span v-else>Нет</span>
                        </td>
                        <td v-if="$props.pivc.isPurchaseSummarized || $props.pivc.hasContract" class="atmo-signature">
                            <button v-on:click.stop="$props.pivc.invokeSignaturePopup([proposal.signature])" class="atmo-button-icon" title="Нажмите, чтобы просмотреть подписи">
                                <i class="atmo-icon-certificate"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { required: true, type: Object },
        proposals: { required: true, type: Array },
        purchaseitem: { required: true, type: Object }
    }
};
</script>

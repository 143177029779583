import { BaseV1ApiService } from '@/services/backend/baseV1';
import { Certificate } from 'crypto-pro';
import { CertificateOwnerInfo, prepareCertificateOwnerInfo } from '@/helpers';
import axios from 'axios';

export class AuthenticationApiService extends BaseV1ApiService {
    constructor() {
        super('auth');
    }

    async checkCertificate(certificate: Certificate, owner_info: CertificateOwnerInfo, is_customer: boolean) {
        try {
            const response = await axios.post(this.getUrl('check_certificate'), {
                certificate,
                owner_info,
                is_customer
            });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async loginByCertificate(certificate: Certificate) {
        const owner_info = await prepareCertificateOwnerInfo(certificate);
        const response = await axios.post(this.getUrl('login'), {
            login_type: 'certificate',
            certificate,
            owner_info
        });
        return response.data;
    }

    async loginByLoginPassword(username: string, password: string) {
        const response = await axios.post(this.getUrl('login'), {
            login_type: 'password',
            username,
            password
        });
        return response.data;
    }

    async loadOrganization(inn: string, kpp: string | null, isProvider: boolean) {
        return axios.post(this.getUrl('load_organization'), { inn, kpp, is_provider: isProvider });
    }

    async registerCustomer(user: any, organization: any, certificate: Certificate, xml: string, poa: any) {
        try {
            const owner_info = await prepareCertificateOwnerInfo(certificate);
            const data = new FormData();
            data.append('payload', JSON.stringify({ user, organization, certificate, xml, owner_info }));
            if (user.signs_with_poa) {
                data.append('poa', poa);
            }
            const response = await axios.post(this.getUrl('register_customer'), data, { headers: { 'Content-Type': 'multipart/form-data' } });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async registerProvider(user: any, organization: any, certificate: Certificate, xml: string, poa: any) {
        try {
            const owner_info = await prepareCertificateOwnerInfo(certificate);
            const data = new FormData();
            data.append('payload', JSON.stringify({ user, organization, certificate, xml, owner_info }));
            if (user.signs_with_poa) {
                data.append('poa', poa);
            }
            const response = await axios.post(this.getUrl('register_provider'), data, { headers: { 'Content-Type': 'multipart/form-data' } });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async checkPoa(poa: File, organizationInn: string, userInn: string, userSnils: string) {
        try {
            const data = new FormData();
            data.append('organization_inn', organizationInn);
            data.append('user_inn', userInn);
            data.append('user_snils', userSnils);
            data.append('poa', poa);
            const response = await axios.post(this.getUrl('check_poa'), data, { headers: { 'Content-Type': 'multipart/form-data' } });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async recall() {
        return await axios.post(this.getUrl('recall'));
    }

    async logout() {
        return await axios.post(this.getUrl('logout'));
    }

    requestEmailConfirmation(email: string) {
        return axios.post(this.getUrl('request_email_confirmation'), { email });
    }

    checkEmailConfirmation(email: string, code: string, username: string | null = null) {
        return axios.post(this.getUrl('check_email_confirmation'), { username, email, code });
    }

    requestPhoneConfirmation(phone: string) {
        return axios.post(this.getUrl('request_phone_confirmation'), { phone });
    }

    checkPhoneConfirmation(phone: string, code: string) {
        return axios.post(this.getUrl('check_phone_confirmation'), { phone, code });
    }

    forgotPassword(username: string) {
        return axios.post(this.getUrl('forgot_password'), { username });
    }

    resetPassword(token: string) {
        return axios.post(this.getUrl('reset_password'), { token });
    }
}

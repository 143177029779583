<template>
    <div id="atmo-contract" class="atmo-hash-jump-point atmo-content-group">
        <h3>Договор</h3>

        <div class="atmo-buttons">
            <a v-if="pivc.isUserEngagedCustomer" href="https://app.hinted.me/simulations/3dd987db-9fcf-429b-b912-8ced475b06b9/view" rel="noopener" target="_blank" class="atmo-button atmo-accented"><i class="atmo-icon-play"></i>&nbsp;Инструкция по заключению договора и дополнительного соглашения</a>
            <a v-if="pivc.isUserEngagedSupplier" href="https://app.hinted.me/simulations/3116f060-cfc9-4600-baa2-68c13a96320a/view" rel="noopener" target="_blank" class="atmo-button atmo-accented"><i class="atmo-icon-play"></i>&nbsp;Инструкция по заключению договора и дополнительного соглашения</a>
        </div>

        <ul v-if="!pivc.isPurchaseOutATMO" class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Предмет договора - наименование закупочной сессии:</dt>
                <dd v-text="this.$props.purchaseitem.description"></dd>
            </li>
            <li v-if="this.$props.purchaseitem.customer_contract_number">
                <dt>Номер договора в системе учета заказчика:</dt>
                <dd v-text="this.$props.purchaseitem.customer_contract_number"></dd>
            </li>
            <li v-if="this.$props.purchaseitem.contract.hasDeliveryCost()">
                <dt>Стоимость доставки, руб.:</dt>
                <dd>{{ this.$props.purchaseitem.contract.delivery_cost | formatnumber }}</dd>
            </li>
            <li>
                <dt>Цена договора, руб.:</dt>
                <dd>{{ this.$props.purchaseitem.contract.price_total | formatnumber }}</dd>
            </li>
            <li>
                <dt>Статус договора, дата:</dt>
                <dd>
                    <span v-text="this.$props.purchaseitem.contract.acceptance_status_title"></span>,
                    <span>{{ this.$props.purchaseitem.contract.acceptance_status_updated_at | formatdate('withSeconds') }}</span>
                </dd>
            </li>
            <li>
                <dt>Статус закупки:</dt>
                <dd v-text="this.$props.purchaseitem.status_title"></dd>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { required: true, type: Object },
        purchaseitem: { required: true, type: Object }
    }
};
</script>

export default [
    {
        path: '/contracts',
        redirect: { name: 'ContractsList', params: { contract: 'all' } }
    },
    {
        path: '/contracts/:contract',
        component: () => import(/* webpackChunkName: "main" */ '@/components/contracts/ContractsList'),
        name: 'ContractsList'
    }
];

<template>
    <b-form-group :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" label-cols-lg="3" label-cols-sm="4">
        <template #label>
            {{ label }}:
            <div v-if="!!hint" class="atmo-help">
                &nbsp;<i class="atmo-icon-question-mark"></i><span class="atmo-help__text">{{ hint }}</span>
            </div>
        </template>
        <b-form-input :id="id" v-model="text" :autocomplete="autocomplete" :disabled="disabled" :maxlength="maxLength" :placeholder="placeholder" :state="state" :type="type" class="fs-14" @change="onChange"></b-form-input>
        <template v-if="!state">
            <b-form-invalid-feedback>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
        <template v-if="description">
            <b-form-text class="desc-text">
                {{ description }}
            </b-form-text>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'form-row-edit-text',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        v: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        maxLength: {
            type: Number,
            default: -1
        },
        errorText: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        },
        autocomplete: {
            type: String,
            default: 'off'
        }
    },
    methods: {
        onChange() {
            this.$emit('change');
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value.replace(/&lrm;|\u200E/gi, ''));
            }
        },
        id() {
            return 'form_row_edit_text_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
                if (!this.v.minLength && !!this.v.$params.minLength) return `Минимальная длина поля - ${this.v.$params.minLength.min} символа/ов`;
                if (!this.v.maxLength && !!this.v.$params.maxLength) return `Максимальная длина поля - ${this.v.$params.minLength.min} символа/ов`;
                if (!this.v.minLength && !!this.v.$params.thumbprint) return 'Отпечаток содержит недопустимые символы';
                if (!this.v.numeric && !!this.v.$params.numeric) return 'Можно использовать только цифры (0–9)';
                if (!this.v.confirmPassword && !!this.v.$params.confirmPassword) return 'Пароли не совпадают';
                if (!this.v.allowable && !!this.v.$params.allowable) return 'Недопустимые символы в пароле. Можно использовать только буквы латинского алфавита (a–z и A–Z), цифры (0–9) и такие специальные символы, как !@#$%^&*.().';
                if (!this.v.containsSymbols && !!this.v.$params.containsSymbols) return 'Пароль должен содержать минимум 2 латинские буквы разного регистра и цифры';
                if (!this.v.loginBackError && !!this.v.$params.loginBackError) return this.errorText;
                if (!this.v.url && !!this.v.$params.url) return 'Введите валидный URL-адрес';
            }
            return '';
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}

.desc-text {
    color: var(--green-color) !important;
}
</style>

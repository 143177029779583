var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-price-breakdown" },
    },
    [
      _c("h3", [_vm._v("Стоимость")]),
      _c(
        "ul",
        { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
        [
          _c("li", [
            _c("dt", [_vm._v("Начальная цена контракта, руб.:")]),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm._f("formatnumber")(
                    this.$props.pricebreakdown.starting_price
                  )
                )
              ),
            ]),
          ]),
          _c("li", [
            _c("dt", [_vm._v("Финальная цена договора (сделки), руб.:")]),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm._f("formatnumber")(this.$props.pricebreakdown.final_price)
                )
              ),
            ]),
          ]),
          _c("li", [
            _c("dt", [_vm._v("Размер скидки, руб. / %:")]),
            _c("dd", [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatnumber")(this.$props.pricebreakdown.discount)
                  )
                ),
              ]),
              _vm._v("  /  "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatnumber:rounded")(
                      this.$props.pricebreakdown.discount_percent
                    )
                  ) + "%"
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <!-- v-bind:currentstate="true" -->
    <on-off v-slot:default="onoff" v-bind:options="{ onEsc: false, onBody: false }">
        <section class="atmo-annex">
            <header>
                <ul class="atmo-buttons">
                    <li>
                        <button v-on:click.stop="onoff.toggle" class="atmo-button" title="Показать/скрыть дополнительное соглашение">
                            Дополнительное соглашение № <span v-text="$props.index + 1"></span>&nbsp;
                            <span v-if="onoff.ison" class="atmo-icon-angle-up"></span>
                            <span v-else class="atmo-icon-angle-down"></span>
                        </button>
                    </li>
                    <li>
                        <button v-if="annex.isSignedByBoth()" v-on:click="$props.pivc.downloadAnnex(annex, $props.index + 1)" class="atmo-button-icon" title="Нажмите, чтобы скачать дополнительное соглашение в формате PDF">
                            <i class="atmo-icon-pdf"></i>
                        </button>
                    </li>
                    <li>
                        <button v-if="annex.isSignedByBoth()" v-on:click.stop="$props.pivc.invokeSignaturePopup([annex.signatures.supplier, annex.signatures.customer])" class="atmo-button-icon" title="Нажмите, чтобы просмотреть подписи">
                            <i class="atmo-icon-certificate"></i>
                        </button>
                    </li>
                </ul>

                <p v-if="annex.isSignedByBoth()"><b>Подписано поставщиком и заказчиком</b></p>
                <p v-else-if="annex.is_signed_by.supplier"><b>Подписано поставщиком</b></p>
                <p v-else>
                    <b
                        >Ожидает подписи
                        <span v-if="!annex.is_signed_by.supplier">поставщика, </span>
                        <span v-if="!annex.is_signed_by.customer">заказчика</span>
                    </b>
                </p>
            </header>

            <main v-if="onoff.ison">
                <ul class="atmo-content-block-2-columns atmo-no-item-separator">
                    <li>
                        <dt>Общая стоимость, руб.:</dt>
                        <dd>{{ $props.annex.price_total | formatnumber }}</dd>
                    </li>
                    <li>
                        <dt>Дата заключения дополнительного соглашения:</dt>
                        <dd>
                            <span>{{ $props.annex.date | formatdate('onlyDate') }}</span>
                        </dd>
                    </li>
                </ul>

                <AnnexAttachments.view v-bind:pivc="$props.pivc" v-bind:annex="$props.annex" />

                <DeliverablesList.view v-bind:origin="'contract'" v-bind:deliverables="$props.annex.deliverables"
                    ><span>дополнительного соглашения №{{ $props.index + 1 }}</span>
                </DeliverablesList.view>

                <KBKLimitsList.view v-if="!$props.pivc.purchaseitem.without_limits && $props.pivc.features.kbk_limits && pivc.isUserEngagedCustomer && pivc.isContractSignedByCustomer" v-bind:limits="$props.annex.kbk_limits" />

                <KBKLimitsAutonomousUpdateWidget.view
                    v-if="!$props.pivc.purchaseitem.without_limits && $props.pivc.features.kbk_limits && canEditLimitsOnActualAnnex"
                    class="atmo-is-nested"
                    v-bind:purchaseid="$props.pivc.purchaseitem.id"
                    v-bind:entity="$props.annex"
                    v-bind:limitfilterpresets="{ year: $props.pivc.purchaseitem.limit_year, purchase_type_id: $props.pivc.purchaseitem.type_id, purchase_id: $props.pivc.purchaseitem.id }" />

                <KBKLimitsEditableList.view
                    v-if="!$props.pivc.purchaseitem.without_limits && $props.pivc.features.kbk_limits && canEditLimitsOnPedingAnnex"
                    class="atmo-is-nested"
                    v-bind:initiallist="$data.kbk_limits"
                    v-bind:totalamount="$props.annex.price_total"
                    v-bind:limitfilterpresets="{ year: $props.pivc.purchaseitem.limit_year, purchase_type_id: $props.pivc.purchaseitem.type_id, purchase_id: $props.pivc.purchaseitem.id }">
                    <template v-slot:title>
                        <h5>Измените лимиты</h5>
                    </template>
                </KBKLimitsEditableList.view>

                <ContractSupplierAccounting.view
                    v-if="
                        $props.annex.isPending() &&
                        $props.pivc.isUserEngagedCustomer &&
                        $props.pivc.hasContractPendingAnnexes &&
                        $props.pivc.pendingAnnex.isSignedBySupplier() &&
                        !$props.pivc.pendingAnnex.isSignedByCustomer() &&
                        !$props.pivc.purchaseitem.without_limits &&
                        $props.pivc.features.kbk_limits
                    "
                    v-bind:initialData="$props.annex.supplier_accounting"
                    v-on:select="updateSupplierAccounting">
                    <span>Банковские реквизиты поставщика</span>
                </ContractSupplierAccounting.view>

                <ContractSupplierAccountingInfo.view
                    v-if="$props.annex.supplier_accounting && $props.pivc.isUserEngagedCustomer && $props.annex.isSignedByCustomer() && $props.annex.isSignedBySupplier() && !$props.pivc.purchaseitem.without_limits && $props.pivc.features.kbk_limits"
                    v-bind:supplier-accounting="$props.annex.supplier_accounting">
                    <span>Банковские реквизиты поставщика</span>
                </ContractSupplierAccountingInfo.view>

                <div v-if="$props.pivc.hasContractPendingAnnexes && !$props.pivc.pendingAnnex.hasUnresolvedDisputes()" class="atmo-buttons">
                    <button v-if="$props.annex.isPending() && $props.pivc.isUserEngagedSupplier && !$props.pivc.pendingAnnex.isSignedBySupplier()" v-on:click.stop="$props.pivc.invokeSignContractAnnexPopup" :disabled="!$store.getters.getCanSign" class="atmo-button">
                        Подписать дополнительное соглашение и отправить Заказчику
                    </button>

                    <button
                        v-if="$props.annex.isPending() && $props.pivc.isUserEngagedCustomer && $props.pivc.hasContractPendingAnnexes && $props.pivc.pendingAnnex.isSignedBySupplier() && !$props.pivc.pendingAnnex.isSignedByCustomer()"
                        v-bind:disabled="(!$props.pivc.purchaseitem.without_limits && $props.pivc.features.kbk_limits && (has_limits_errors || has_supplier_accounting_errors)) || !$store.getters.getCanSign"
                        v-on:click.stop="invokeSignContractAnnexPopup"
                        class="atmo-button">
                        Подписать дополнительное соглашение
                    </button>
                    <template v-if="!$store.getters.getCanSign">
                        <span v-if="$store.getters.getLoginType === 'cert'" class="text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span>
                        <span v-else class="text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
                    </template>
                </div>

                <AnnexDisputes.view v-if="$props.annex.hasDisputes()" v-bind:pivc="$props.pivc" v-bind:disputes="$props.annex.disputes" />

                <ContractAnnexDisputeResolutionWidget.view
                    v-if="($props.pivc.isUserEngagedSupplier && !$props.annex.hasUnresolvedDisputes() && !$props.annex.isSignedBySupplier()) || ($props.pivc.isUserEngagedCustomer && $props.annex.hasUnresolvedDisputes() && !$props.annex.isSignedByCustomer())"
                    v-bind:pivc="$props.pivc"
                    v-bind:annex="$props.annex" />
            </main>
        </section>
    </on-off>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import AnnexAttachmentsView from './partials/AnnexAttachments.view.vue';
import AnnexDisputesView from './partials/AnnexDisputes.view.vue';
import DeliverablesListView from '../../../../common/partials/deliverables/view/DeliverablesList.view.vue';
import KBKLimitsListView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/view/KBKLimitsList.view.vue';
import KBKLimitsAutonomousUpdateWidgetView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/update/KBKLimitsAutonomousUpdateWidget.view.vue';
import KBKLimitsEditableListView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/update/KBKLimitsEditableList.view.vue';
import ContractAnnexDisputeResolutionWidgetView from 'themes/views/pages/purchases/item/view/partials/update/annexes/ContractAnnexDisputeResolutionWidget.view.vue';
import KBKLimitsSpecificationItemsEditableCollection from '@/rearchitected/core/js/ddriven/domain/model/purchases/update/KBKLimitsSpecificationItemsEditable.collection';
import ContractSupplierAccountingView from 'themes/views/pages/purchases/item/view/partials/contract/ContractSupplierAccounting.view.vue';
import ContractSupplierAccountingInfoView from 'themes/views/pages/purchases/item/view/partials/contract/ContractSupplierAccountingInfo.view.vue';
import SupplierAccountingVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierAccounting.valueobject';

export default {
    // REFACTOR: The viewmodel is on the verge of being extracted.
    // Extract when it spills over and wrap around the KBKLimitsEditableList.view.
    data() {
        return {
            kbk_limits: this.$props.annex.kbk_limits.duplicate(),
            supplier_accounting: this.$props.annex.supplier_accounting.duplicate()
        };
    },
    computed: {
        canEditLimitsOnPedingAnnex() {
            return this.$props.annex.isPending() && this.$props.pivc.isUserEngagedCustomer && this.$props.pivc.hasContractPendingAnnexes && this.$props.pivc.pendingAnnex.isSignedBySupplier() && !this.$props.pivc.pendingAnnex.isSignedByCustomer();
        },
        canEditLimitsOnActualAnnex() {
            return this.$props.pivc.purchaseitem.contract.isActualAnnex(this.$props.annex) && this.$props.pivc.isUserEngagedCustomer;
        },
        has_limits_errors() {
            return this.$data.kbk_limits.hasErrors() || this.$props.annex.price_total !== this.$data.kbk_limits.distributed_amount_total;
        },
        has_supplier_accounting_errors() {
            return this.$data.supplier_accounting.hasErrors();
        }
    },
    methods: {
        invokeSignContractAnnexPopup: function () {
            // NB: Manually upate limits in the pending annex.
            this.canEditLimitsOnPedingAnnex && (this.$props.annex.kbk_limits = new KBKLimitsSpecificationItemsEditableCollection(...this.$data.kbk_limits.items));
            this.$props.annex.supplier_accounting = new SupplierAccountingVO(this.$data.supplier_accounting);
            this.$props.pivc.invokeSignContractAnnexPopup();
        },
        updateSupplierAccounting(supplierAccounting) {
            this.$data.supplier_accounting = new SupplierAccountingVO(supplierAccounting);
        }
    },
    props: {
        pivc: { require: true, type: Object },
        annex: { required: true, type: Object },
        index: { required: true, type: Number }
    },
    components: {
        'on-off': new OnOff(),
        'AnnexAttachments.view': AnnexAttachmentsView,
        'DeliverablesList.view': DeliverablesListView,
        'KBKLimitsList.view': KBKLimitsListView,
        'KBKLimitsAutonomousUpdateWidget.view': KBKLimitsAutonomousUpdateWidgetView,
        'KBKLimitsEditableList.view': KBKLimitsEditableListView,
        'AnnexDisputes.view': AnnexDisputesView,
        'ContractAnnexDisputeResolutionWidget.view': ContractAnnexDisputeResolutionWidgetView,
        'ContractSupplierAccounting.view': ContractSupplierAccountingView,
        'ContractSupplierAccountingInfo.view': ContractSupplierAccountingInfoView
    }
};
</script>

<style scoped>
.text-initialcase {
    text-transform: initial;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "atmo-content-table" }, [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(this.$props.disputes, function (dispute, index) {
          return _c(
            "tr",
            { key: index },
            [
              _c("td", [
                _c("button", {
                  staticClass: "atmo-button-link",
                  domProps: {
                    textContent: _vm._s(dispute.protocol.attachment.name),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$props.pivc.downloadFileAttachment(
                        dispute.protocol.attachment
                      )
                    },
                  },
                }),
              ]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatdate")(
                      dispute.protocol.created_at,
                      "withSeconds"
                    )
                  )
                ),
              ]),
              _c("td", {
                domProps: { textContent: _vm._s(dispute.protocol.comment) },
              }),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "atmo-button-icon",
                    attrs: { title: "Нажмите, чтобы просмотреть подпись" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$props.pivc.invokeSignaturePopup([
                          dispute.protocol.signature,
                        ])
                      },
                    },
                  },
                  [_c("i", { staticClass: "atmo-icon-certificate" })]
                ),
              ]),
              !dispute.reply.isEmpty()
                ? [
                    _c("td", [
                      _c("button", {
                        staticClass: "atmo-button-link",
                        domProps: {
                          textContent: _vm._s(dispute.reply.attachment.name),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$props.pivc.downloadFileAttachment(
                              dispute.reply.attachment
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatdate")(
                            dispute.reply.created_at,
                            "withSeconds"
                          )
                        )
                      ),
                    ]),
                    _c("td", {
                      domProps: { textContent: _vm._s(dispute.reply.comment) },
                    }),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "atmo-button-icon",
                          attrs: {
                            title: "Нажмите, чтобы просмотреть подпись",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$props.pivc.invokeSignaturePopup([
                                dispute.reply.signature,
                              ])
                            },
                          },
                        },
                        [_c("i", { staticClass: "atmo-icon-certificate" })]
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Протокол разногласий (поставщик)")]),
        _c("th", [_vm._v("Дата и время направления протокола")]),
        _c("th", [_vm._v("Комментарий поставщика")]),
        _c("th", [_vm._v("Подпись")]),
        _c("th", [_vm._v("Ответ на протокол разногласий (заказчик)")]),
        _c("th", [_vm._v("Дата и время направления ответа")]),
        _c("th", [_vm._v("Комментарий заказчика")]),
        _c("th", [_vm._v("Подпись")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
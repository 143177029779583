<template>
    <div id="atmo-contract-fulfillment" class="atmo-hash-jump-point atmo-content-group">
        <h3>Исполнение договора</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Статус закупки:</dt>
                <dd v-text="this.$props.pivc.status.title"></dd>
            </li>
        </ul>

        <div v-if="this.$props.pivc.purchaseitem.contract.hasFulfillment()">
            <h5>Документы об исполнении</h5>

            <div class="table-responsive">
                <table class="atmo-content-table">
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Документ</th>
                            <th>Дата и время загрузки</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(attachment, index) in this.$props.attachments" v-bind:key="index">
                            <td v-text="index + 1"></td>
                            <td>
                                <button v-on:click="$props.pivc.downloadFileAttachment(attachment)" class="atmo-button-link" v-text="attachment.name"></button>
                            </td>
                            <td>{{ attachment.created_at | formatdate('withSeconds') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { required: true, type: Object },
        attachments: { required: true, type: Array }
    }
};
</script>

<template>
    <div class="table-responsive">
        <table class="atmo-content-table">
            <thead>
                <tr>
                    <th>Протокол разногласий (поставщик)</th>
                    <th>Дата и время направления протокола</th>
                    <th>Комментарий поставщика</th>
                    <th>Подпись</th>
                    <th>Ответ на протокол разногласий (заказчик)</th>
                    <th>Дата и время направления ответа</th>
                    <th>Комментарий заказчика</th>
                    <th>Подпись</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(dispute, index) in this.$props.disputes" v-bind:key="index">
                    <!-- Protocol -->
                    <td>
                        <button v-on:click="$props.pivc.downloadFileAttachment(dispute.protocol.attachment)" class="atmo-button-link" v-text="dispute.protocol.attachment.name"></button>
                    </td>
                    <td>{{ dispute.protocol.created_at | formatdate('withSeconds') }}</td>
                    <td v-text="dispute.protocol.comment"></td>
                    <td>
                        <button v-on:click.stop="$props.pivc.invokeSignaturePopup([dispute.protocol.signature])" class="atmo-button-icon" title="Нажмите, чтобы просмотреть подпись">
                            <i class="atmo-icon-certificate"></i>
                        </button>
                    </td>

                    <!-- Reply -->
                    <template v-if="!dispute.reply.isEmpty()">
                        <td>
                            <button v-on:click="$props.pivc.downloadFileAttachment(dispute.reply.attachment)" class="atmo-button-link" v-text="dispute.reply.attachment.name"></button>
                        </td>
                        <td>{{ dispute.reply.created_at | formatdate('withSeconds') }}</td>
                        <td v-text="dispute.reply.comment"></td>
                        <td>
                            <button v-on:click.stop="$props.pivc.invokeSignaturePopup([dispute.reply.signature])" class="atmo-button-icon" title="Нажмите, чтобы просмотреть подпись">
                                <i class="atmo-icon-certificate"></i>
                            </button>
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { required: true, type: Object },
        disputes: { required: true, type: Array }
    }
};
</script>

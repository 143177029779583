<template>
    <div>
        <!-- // DEPRECATED: Heading already existst below;  -->
        <!-- <p class="fs-32 grey-color my-4">Спецификации</p> -->
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <h2 class="grey-color font-weight-bold my-3">Спецификация</h2>
                <div style="text-align: right">
                    <b-button @click="showSpec = true" variant="custom-green" v-if="!noAddSpec">Добавить позицию спецификации</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row class="py-3" no-gutters>
            <b-col class="fs-14 grey-color">
                <div class="table-responsive" :class="v.$invalid || specErrors ? 'is-invalid' : ''">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="1"></th>
                                <th>Наименование товара/работы/услуги</th>
                                <th width="160px" style="min-width: 140px" class="text-right">Количество</th>
                                <th width="100px" nowrap>Ед.изм</th>
                                <th v-if="withPrice" width="160px" style="min-width: 140px" class="text-right">Цена за ед., руб.</th>
                                <th v-if="withPrice" width="140px" style="min-width: 140px" class="text-right">Стоимость, руб.</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <fragment v-for="(item, index) in specs" :key="index">
                                <tr>
                                    <td class="align-text-top">{{ item.number }}</td>
                                    <td class="align-text-top">
                                        <action-button-small :title="(!visible[index] ? 'Показать' : 'Скрыть') + ' характеристики'" v-b-toggle="'collapse-' + index" :icon="!visible[index] ? 'eye' : 'eye-slash'" />
                                        <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                        {{ item.name }}
                                    </td>
                                    <td style="text-align: right" class="align-text-top" v-if="withCountEdit">
                                        <formatted-input-number v-slot:default="fim" v-bind:decimals="9" v-bind:padright="false">
                                            <div class="atmo-component-wrapper">
                                                <button v-if="!fim.isInputFocused" v-on:click.stop="fim.focus" v-on:focus="fim.onTriggerFocus" v-bind:class="{ 'atmo-invalid': !fim.isInputValid }" class="atmo-button--small atmo-input" v-text="fim.formatted"></button>

                                                <span v-show="fim.isInputFocused" class="atmo-form__field atmo-inverse atmo-small">
                                                    <input
                                                        v-model.number="specs[index].count"
                                                        v-bind:disabled="isDeliverySpec(item)"
                                                        v-on:blur="fim.processBlurEvent"
                                                        v-on:keydown="fim.processKeydownEvent"
                                                        v-on:input="fim.processInputEvent"
                                                        v-stop-number-mousewheel
                                                        required
                                                        type="number"
                                                        step="0.000000001"
                                                        min="0.000000001" />
                                                </span>
                                            </div>
                                        </formatted-input-number>
                                    </td>
                                    <td style="text-align: right" class="align-text-top" v-else>
                                        {{ parseFloat(specs[index].count).toPrecision() }}
                                    </td>
                                    <td class="align-text-top">{{ item.okei.local_symbol }}</td>
                                    <td v-if="withPrice" class="align-text-top">
                                        <currency-input v-model="specs[index].price" class="form-control fs-14 text-right" currency="RUB" locale="ru" :class="specs[index].price > 0 ? '' : 'is-invalid'" />
                                    </td>
                                    <td v-if="withPrice" style="text-align: right" class="align-text-top">
                                        {{ $formatPrice(roundToTwoDecimals(specs[index].price * specs[index].count)) }}
                                    </td>
                                    <td nowrap class="align-text-top">
                                        <action-button-small v-if="!noDeleteSpec || isDeliverySpec(item)" icon="trash" @click="removeSpec(index)" />
                                    </td>
                                </tr>
                                <tr v-show="visible[index]">
                                    <td :colspan="withPrice ? 7 : 5">
                                        <p><b>Рег.номер ТРУ:</b> {{ item.ktru.reg_number }}</p>
                                        <p><b>Группа ТРУ:</b> {{ item.ktru.ktru_group_name }}</p>
                                        <p><b>Наименование:</b> {{ item.ktru.name }}</p>
                                        <p><b>Тип:</b> {{ item.ktru.type }}</p>
                                        <p v-if="item.okpd"><b>Код ОКПД2:</b> {{ item.okpd ? item.okpd.code : '&mdash;' }}</p>
                                        <p v-if="item.ktru.description"><b>Описание:</b> {{ item.ktru.description }}</p>
                                        <p v-if="item.ktru.specs && item.ktru.specs.length > 0"><b>Характеристики:</b></p>
                                        <ul v-if="item.ktru.specs && item.ktru.specs.length > 0">
                                            <li v-for="(spec, specIndex) of item.ktru.specs" :key="index + '_spec_' + specIndex">
                                                <b>{{ spec.name }}</b> - {{ spec.value }}
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </fragment>
                            <tr v-if="specs.length === 0">
                                <td :colspan="withPrice ? 7 : 5" align="center">Не добавлено позиций спецификации</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p v-if="specErrors" class="text-danger">{{ specErrorMessage }}</p>
            </b-col>
        </b-row>
        <text-row-price v-if="withPrice" label="Общая стоимость" :value="roundedSpecSum" />

        <purchase-specification-modal :show="showSpec" @cancel="cancelSpecModal" @add="addSpec" />
    </div>
</template>

<script>
import { CurrencyDirective } from 'vue-currency-input';
import Directories from '@/services/api/Directories';
import PurchaseSpecificationModal from '@/components/common/purchase-specification-modal';
import FormattedInputNumber from '@lib/js/src/vue/components/FormattedInputNumber.viewmodel';
import roundToTwoDecimals from '../../../rearchitected/lib/js/src/misc/roundToTwoDecimals';

export default {
    name: 'FormRowSpecificationTable',
    props: {
        value: {
            type: Array,
            required: true
        },
        grey: {
            type: Boolean,
            default: false
        },
        v: {
            type: Object,
            required: true
        },
        withPrice: {
            type: Boolean,
            default: false
        },
        withCountEdit: {
            type: Boolean,
            default: false
        },
        specSum: {
            type: Number
        },
        specErrors: {
            type: Boolean
        },
        specErrorMessage: {
            type: String,
            default: ''
        },
        noAddSpec: {
            type: Boolean,
            default: false
        },
        noDeleteSpec: {
            type: Boolean,
            default: false
        },
        withAddDelivery: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'formatted-input-number': FormattedInputNumber,
        PurchaseSpecificationModal
    },
    directives: {
        currency: CurrencyDirective
    },
    data() {
        return {
            spec: {
                number: 1,
                order: 1,
                name: '',
                count: 1,
                okei: null,
                ktru_item_id: null,
                ktru: null,
                price: 0
            },
            showSpec: false,
            tmpSpecs: [],
            visible: []
        };
    },
    methods: {
        roundToTwoDecimals,
        async addDelivery() {
            const ktruItemResponse = await Directories.getKtruItem(this.$deliveryKtruItemId);
            const ktruItem = ktruItemResponse.data;
            const spec = {
                number: 1,
                order: 1,
                name: ktruItem.name,
                count: 1.0,
                okei: ktruItem.okei,
                okpd: ktruItem.okpd,
                ktru: ktruItem,
                ktru_item_id: ktruItem.id
            };
            this.addSpec(spec);
        },
        isDeliverySpec(spec) {
            return spec.ktru_item_id === this.$deliveryKtruItemId;
        },
        cancelSpecModal() {
            this.spec = { number: 1, order: 1, name: '', count: 1, okei: null, okpd: null, ktru_item_id: null, ktru: null, price: 0 };
            this.showSpec = false;
        },
        addSpec(spec) {
            this.tmpSpecs = this.specs;
            if (!spec.ktru_item_id) {
                this.pushToast({
                    text: 'Неверный формат спецификации',
                    title: 'Ошибка',
                    variant: 'danger'
                });
            } else {
                this.showSpec = false;
                const alreadyExists = this.specs.find((i) => i.ktru_item_id === spec.ktru_item_id);
                if (alreadyExists) {
                    return;
                }
                let newSpec = JSON.parse(JSON.stringify(spec)); // clone
                newSpec['number'] = this.tmpSpecs.length + 1;
                newSpec['order'] = this.tmpSpecs.length + 1;
                if (this.withPrice) {
                    newSpec['price'] = 0;
                }
                this.tmpSpecs.push(newSpec);
                this.specs = this.tmpSpecs;
            }
        },
        removeSpec(index) {
            this.tmpSpecs = this.specs;
            this.tmpSpecs.splice(index, 1);
            let startIndex = 1;
            this.tmpSpecs.forEach((item) => {
                item.number = startIndex;
                item.order = startIndex;
                startIndex++;
            });
            this.specs = this.tmpSpecs;
        }
    },
    computed: {
        specs: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        roundedSpecSum() {
            return Math.round(this.specSum * 100) / 100;
        },
        hasDeliverySpec() {
            return this.value.filter((e) => e.ktru_item_id === this.$deliveryKtruItemId).length > 0;
        }
    }
};
</script>

<style scoped>
.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 8px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
